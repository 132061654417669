@import './styles/portal-colors.scss';

.App {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
}
