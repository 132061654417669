@import 'bulma';
@import '~bulma-tooltip';

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: calc(100% - 3rem);
}

input[type='checkbox'] {
  margin-right: 0.5rem;
}

.select {
  width: 100%;

  select {
    width: 100%;
  }
}

.label {
  font-weight: 600;
}

.is-no-margin-bottom {
  margin-bottom: 0 !important;
}

a.button.is-large {
  line-height: normal;
}

@import './styles/popup-overrides.scss';
