.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offerImage {
  // TODO: Fit height of desktop browser window automatically
  min-height: 400px;
  max-height: 1000px;
}

.offerExternalLink {
  a {
    float: none;
    margin: 1rem auto;
  }
}

.offerElements {
  background-color: white;
  box-shadow: 0px 3px 10px #0006;
}
