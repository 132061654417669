@import '../../../styles/portal-colors.scss';
@import '../../../styles/mixins.scss';
@import '@greensync/component-library/dist/styles/mixins.scss';

.header {
  height: 3rem;
  display: flex;
  @include box-shadow;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 99;
  i {
    overflow: hidden;
  }
}

.leftColumn,
.rightColumn {
  display: flex;
}

.headerLogo {
  @include add-padding-to-children(0.5rem, 1rem);
  height: 100%;
  margin-right: 2rem;
}

.filtersContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.loginButton {
  background: $highlight-primary;
  text-transform: uppercase;
  border: none;
  padding: 0 1.5rem;
  font-size: 14px;
  height: 3rem;
  line-height: 3rem;
  display: flex;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  &,
  &:hover {
    color: #000;
  }
  i {
    margin-right: 0.5rem;
    height: 3rem;
  }

  @media only screen and (max-width: 500px) {
    i {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 500px) {
    .authLabel {
      display: none;
    }
  }
}

.menuButton {
  height: auto;
  border: none;
  border-left: 1px solid $light-grey;
  position: relative;
  border-color: $light-grey;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;

  &:hover {
    border-color: $medium-grey;
  }
}

.menuContainer {
  position: fixed;
  height: calc(100% - 3rem);
  background-color: rgba($charcoal, 0.9);
  top: 3rem;
  display: none;
  z-index: 90;
}

.show {
  display: block;

  .menu {
    display: block;
  }
}

.menu {
  color: $white;
  background-color: rgba($primary, 0.9);
  position: fixed;
  height: calc(100% - 3rem);
  right: 0;
  z-index: 100;
  top: 3rem;
  display: none;
  padding: 4rem 3rem;
  min-width: 300px;

  li {
    font-size: 1.5rem;
    font-weight: normal;
    padding: 2rem 0;
    text-align: center;
    border-bottom: 1px solid $buff;

    a,
    a:visited {
      color: $white;
      text-decoration: none;
    }
  }
}
