.popup-content {
  height: 100%;
  z-index: 9 !important;
}

@media only screen and (max-width: 500px) {
  .popup-content {
    min-width: 300px;
    max-height: 300px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1200px) {
  .popup-content {
    min-width: 400px;
    max-height: 400px;
  }
}

@media only screen and (min-width: 1201px) {
  .popup-content {
    min-width: 600px;
    max-height: 560px;
  }
}
