.triggerBtn {
  border: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-left: 6px;

  i {
    margin-top: 8px;
    color: #4a4a4a;
  }
}
