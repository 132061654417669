.gridWrapper {
  display: grid;
  height: inherit;
}

.bgContainer {
  background: url('/background-light.jpg') no-repeat center center fixed;
  background-color: #d4e7ee;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: inherit;
}

.paddedColumnsContainer {
  padding: 1rem;
  margin-top: 0;
}

.inheritHeight {
  height: inherit;
}

.sideColumn {
  min-width: 300px;
}
