$matteBlue: #292f40;
$lightGray: #dbdbdb;

.roundedButton {
  padding: 12px 20px;
  background: $matteBlue;
  border: 1px solid $matteBlue;
  border-radius: 50px;
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: block;
  width: fit-content;
  text-align: center;
  margin-left: auto;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }

  &:disabled {
    background: #8a8d97;
    cursor: no-drop;
  }
}

.roundedButtonHighlighted {
  composes: roundedButton;
  background: #00d8ff;
  border-color: #00d8ff;
  color: #000;

  &:hover,
  &:active,
  &:focus {
    color: #000;
  }
}

.roundedButtonPlain {
  composes: roundedButton;
  background: #fff;
  color: $matteBlue;
  border-color: $lightGray;

  &:hover,
  &:active,
  &:focus {
    color: $matteBlue;
    border-color: $matteBlue;
  }
  &:disabled {
    background: #fff;
  }
}

.fullWidth {
  width: 100%;
}

.noLeftMargin {
  margin-left: 0;
}
