$background: #001736;
$medium-gray: #8a8d97;
$matte-blue: #292f40;

.formContainer {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.13);
  margin-bottom: 3rem;
}

.header {
  background-color: $background;
  border-radius: 6px 6px 0 0;
  color: white;
  padding: 12px 16px;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }

  div {
    display: block;
  }
}

.statusIcon {
  float: left;
  font-size: 1rem;

  margin-right: 0.5rem;

  i {
    font-size: 36px;
    height: 36px;
    width: 36px;
  }
}

.step {
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
}

.stepValue {
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
}

.title {
  float: left;
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
}

.collapseButton {
  float: right;
  cursor: pointer;
  line-height: 2rem;
  font-size: 3rem;
}

.content {
  background: white;
  padding: 1rem;
  border-top: 1px solid white;
}

// MODIFICATIONS FOR .disabled
.disabled {
  .step {
    border: 2px solid $medium-gray;
  }

  .header {
    background-color: white;
    color: $medium-gray;
    border-radius: 6px;
  }
}

// MODIFICATIONS FOR .completed
.completed {
  .step {
    border: 2px solid $medium-gray;
  }

  .header {
    background-color: white;
    color: $medium-gray;
  }

  .title {
    color: $matte-blue;
  }

  .content {
    border-top: 1px solid $matte-blue;
  }
}

// MODIFICATIONS FOR .collapsed
.collapsed {
  .header {
    border-radius: 6px;
  }
}
