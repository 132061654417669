@import '../../../styles/portal-colors.scss';

.infoPanel {
  background: $primary;
  color: $buff;
  height: 100%;
  text-align: left;
  padding: 1.5rem;
}

.header {
  height: auto;
  padding-bottom: 2rem;
}

.content {
  line-height: 1.5rem;

  p {
    margin-bottom: 1.4rem;
  }
}
