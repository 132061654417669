.infoMsg {
  background: rgba(0, 196, 179, 0.4);
  margin-bottom: 2rem;
  padding: 0 1rem;
  overflow: auto;
  text-align: left;
  font-size: 1rem;
  border-radius: 6px;
  color: #001736;

  p,
  div {
    margin: 1rem 0;
  }

  ol {
    list-style: decimal;
    padding-left: 2rem;
    margin: 1rem 0;
  }

  ul {
    padding-left: 2rem;
    margin: 1rem 0;
    list-style: disc;
  }

  li {
    margin: 0.5rem 0;
  }
}
